<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <div class="text-center">
        <h1 class="primary--text">Welcome to NiXT Admin</h1>
        <h1 class="text-subtitle-1 grey--text">
          Manage Your IoT Components at Once.
        </h1>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
